import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { StaticTemplate } from '../../../templates/staticTemplate'
import { Layout } from '../../../components/layouts/layout'
import { PageMeta } from '../../../components/page-meta'
import { CalculatorSectionModule } from '../../../components/modules/CalculatorSectionModule'
import { RefinanceCalculator } from '../../../components/calculators'
import { anchorLinkSections, CrossSellToutRefinance } from './constants'
import { usePages } from '../constants'

const ShouldRefinancePage = ({ pageContext, location }) => {
  const [t] = useTranslation()
  const { calculatorsPage, mortgagePage } = usePages()

  const pageData = [
    {
      sectionTitle: t('ShouldRefinancePage.subTitle', {
        defaultValue: 'Should I refinance my mortgage?',
      }),
      richBody: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                marks: [],
                value: t('ShouldRefinancePage.textContent', {
                  defaultValue:
                    'If interest rates are low or you can decrease your loan term, refinancing your mortgage could save a lot of money. Crunch the numbers to see the potential impact of changing your interest rate or loan term on your mortgage payments.',
                }),
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                marks: [],
                value: t('ShouldRefinancePage.textContent2a', {
                  defaultValue:
                    'You’ll be able to compare traditional and enhanced refinance options with this calculator. The ',
                }),
                data: {},
              },
              {
                nodeType: 'text',
                marks: [{ type: 'bold' }],
                value: t('ShouldRefinancePage.textContent2b', {
                  defaultValue: 'traditional refinance',
                }),
                data: {},
              },
              {
                nodeType: 'text',
                marks: [],
                value: t('ShouldRefinancePage.textContent2c', {
                  defaultValue:
                    ' option provides estimated payments for your new loan. Based on your new loan term, this option may show the loan being paid off at a later date than your current mortgage. With an ',
                }),
                data: {},
              },
              {
                nodeType: 'text',
                marks: [{ type: 'bold' }],
                value: t('ShouldRefinancePage.textContent2d', {
                  defaultValue: 'enhanced refinance',
                }),
                data: {},
              },
              {
                nodeType: 'text',
                marks: [],
                value: t('ShouldRefinancePage.textContent2e', {
                  defaultValue:
                    ', you’ll see estimated payments if you would like to pay off the new loan when your existing mortgage term would have ended.',
                }),
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                marks: [],
                value: t('ShouldRefinancePage.textContent3a', {
                  defaultValue:
                    'While this calculator can help you understand how much you might save by refinancing, all numbers provided here are estimates. Please ',
                }),
                data: {},
              },
              {
                nodeType: 'hyperlink',
                content: [
                  {
                    nodeType: 'text',
                    marks: [],
                    value: t('ShouldRefinancePage.textContent3b', {
                      defaultValue: 'talk to a Mortgage Loan Officer',
                    }),
                    data: {},
                  },
                ],
                data: {
                  uri: `/home-lending/mortgage-team`,
                },
              },
              {
                nodeType: 'text',
                marks: [],
                value: t('ShouldRefinancePage.textContent3c', {
                  defaultValue: ' about what your actual savings may be.',
                }),
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
    },
  ]

  return (
    <StaticTemplate pageContext={pageContext}>
      {({ navMenus }) => (
        <Layout
          location={location}
          pageContext={{ ...pageContext, pathname: mortgagePage.urlPath }}
          menu={{ ...navMenus, subnav: [calculatorsPage] }}
          showSubnav
        >
          <PageMeta
            language={pageContext.langKey}
            title={t('ShouldRefinancePage.pageTitle', {
              defaultValue: 'Mortgage Refinance Calculator',
            })}
            pathname="/calculators/mortgage/should-refinance"
          />
          <CalculatorSectionModule
            pageTitle={t('ShouldRefinancePage.title', {
              defaultValue: 'Mortgage Calculators',
            })}
            sections={pageData}
            calculator={<RefinanceCalculator />}
            anchorLinkSections={anchorLinkSections()}
          />
          <CrossSellToutRefinance />
        </Layout>
      )}
    </StaticTemplate>
  )
}

ShouldRefinancePage.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export { ShouldRefinancePage }
export default ShouldRefinancePage
