import { useTranslation } from 'react-i18next'
import { ElevatedTitleCentered } from '../../../components/modules/ElevatedTitleCentered'

const anchorLinkSections = () => {
  const [t] = useTranslation()
  const data = [
    {
      title: t('CalculatorPageMortgage.sidebarHeader', {
        defaultValue: 'Calculators',
      }),
      anchorLinks: [
        {
          to: '/calculators/mortgage',
          name: t('CalculatorPageMortgage.sidebarLink2', {
            defaultValue: 'Should I rent or buy a home?',
          }),
        },
        {
          to: '/calculators/mortgage/payments',
          name: t('CalculatorPageMortgage.sidebarLink1', {
            defaultValue: 'What will my mortgage payments be?',
          }),
        },
        {
          to: '/calculators/mortgage/should-refinance',
          name: t('CalculatorPageMortgage.sidebarLink4', {
            defaultValue: 'Should I refinance my mortgage?',
          }),
        },
        {
          to: '/calculators/mortgage/home-equity',
          name: t('CalculatorPageMortgage.sidebarLink3', {
            defaultValue: "How much can I borrow from my home's equity?",
          }),
        },
        {
          to: '/calculators/mortgage/tax-savings',
          name: t('CalculatorPageMortgage.sidebarLink5', {
            defaultValue: 'How much will my mortgage save me in taxes?',
          }),
        },
      ],
    },
    {
      title: t('CalculatorPageMortgage.sidebarHeader2', {
        defaultValue: 'More Calculators',
      }),
      subtitle: t('CalculatorPageMortgage.sidebarSubtitle', {
        defaultValue: 'Powered by EllieMae®',
      }),
      anchorLinks: [
        {
          to: 'https://elevationscu.mymortgage-online.com/RefinanceBreakEven.html',
          name: t('CalculatorPageMortgage.sidebarLink6', {
            defaultValue: 'When would I break even by refinancing?',
          }),
          target: '_blank',
        },
        {
          to: 'https://elevationscu.mymortgage-online.com/PrepaymentSavings.html',
          name: t('CalculatorPageMortgage.sidebarLink7', {
            defaultValue: 'How much could I save by prepaying my mortgage?',
          }),
          target: '_blank',
        },
        {
          to: 'https://elevationscu.mymortgage-online.com/EarlyPayoff.html',
          name: t('CalculatorPageMortgage.sidebarLink8', {
            defaultValue: 'Should I pay off my mortgage early?',
          }),
          target: '_blank',
        },
        {
          to: 'https://elevationscu.mymortgage-online.com/DebtConsolidation.html',
          name: t('CalculatorPageMortgage.sidebarLink9', {
            defaultValue: 'Should I consolidate other debt with my mortgage?',
          }),
          target: '_blank',
        },
      ],
    },
  ]
  return data
}

export { anchorLinkSections }
export default anchorLinkSections

const CrossSellToutMortgage = () => {
  const [t] = useTranslation()

  const richHeadline = {
    nodeType: 'document',
    data: {},
    content: [
      {
        nodeType: 'paragraph',
        content: [
          {
            nodeType: 'text',
            marks: [],
            value: t('CalculatorPageMortgage.ctaHeadlineMortgage', {
              defaultValue: 'If your next home is adding up, we can help make it happen.',
            }),
            data: {},
          },
        ],
        data: {},
      },
    ],
  }

  return (
    <ElevatedTitleCentered
      richHeadline={richHeadline}
      buttonUrl="/home-lending/mortgage"
      buttonCta={t('CalculatorPageMortgage.ctaButton', {
        defaultValue: 'Learn more',
      })}
      buttonAlt={t('CalculatorPageMortgage.ctaButtonAltMortgage', {
        defaultValue: 'Learn more about mortgages',
      })}
    />
  )
}

export { CrossSellToutMortgage }

const CrossSellToutRefinance = () => {
  const [t] = useTranslation()

  const richHeadline = {
    nodeType: 'document',
    data: {},
    content: [
      {
        nodeType: 'paragraph',
        content: [
          {
            nodeType: 'text',
            marks: [],
            value: t('CalculatorPageMortgage.ctaHeadlineRefinance', {
              defaultValue: 'Ready to refinance? Take the next step.',
            }),
            data: {},
          },
        ],
        data: {},
      },
    ],
  }

  return (
    <ElevatedTitleCentered
      richHeadline={richHeadline}
      buttonUrl="/home-lending/refinance"
      buttonCta={t('CalculatorPageMortgage.ctaButton', {
        defaultValue: 'Learn more',
      })}
      buttonAlt={t('CalculatorPageMortgage.ctaButtonAltRefinance', {
        defaultValue: 'Learn more about refinance',
      })}
    />
  )
}

export { CrossSellToutRefinance }

const CrossSellToutEquity = () => {
  const [t] = useTranslation()

  const richHeadline = {
    nodeType: 'document',
    data: {},
    content: [
      {
        nodeType: 'paragraph',
        content: [
          {
            nodeType: 'text',
            marks: [],
            value: t('CalculatorPageMortgage.ctaHeadlineEquity', {
              defaultValue: "We can help put your home's equity to work.",
            }),
            data: {},
          },
        ],
        data: {},
      },
    ],
  }

  return (
    <ElevatedTitleCentered
      richHeadline={richHeadline}
      buttonUrl="/home-lending/home-equity"
      buttonCta={t('CalculatorPageMortgage.ctaButton', {
        defaultValue: 'Learn more',
      })}
      buttonAlt={t('CalculatorPageMortgage.ctaButtonAltEquity', {
        defaultValue: 'Learn more about home equity',
      })}
    />
  )
}

export { CrossSellToutEquity }
